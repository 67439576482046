import React from "react";
import { useOktaAuth } from "@okta/okta-react";
//import useAuthUser from "../hooks/getUser";

export default function MainHeader({ pageTitle }) {
  const { oktaAuth, authState } = useOktaAuth();
  //const userInfo = useAuthUser();
  let given_name = localStorage.getItem('name');

  const logout = async () => await oktaAuth.signOut()
  //console.log(userInfo)

  return (
    <div className="main-header">
      <div className="main-header-logo">
        <div className="logo-header" data-background-color="dark">
          <img
            src="/assets/img/kaiadmin/logo_light.svg"
            alt="navbar brand"
            className="navbar-brand"
            height="20"
          />
        </div>
      </div>
      <nav className="navbar navbar-header navbar-header-transparent navbar-expand-lg border-bottom">
        <div className="container-fluid">
          <nav
            className="navbar navbar-header-left navbar-expand-lg navbar-form nav-search p-0 d-none d-lg-flex"
          >
            <img src="/assets/img/amway-logo.jpg" style={{ "width": "25%", "paddingLeft": "40px" }} alt="Logo" />
            <ul className="navbar-nav topbar-nav ms-md-auto align-items-left">
              <li></li>
              <li>
                <h3 className="fw-bold mb-3">{pageTitle}</h3>
              </li>
              <li></li>
            </ul>
          </nav>

          <ul className="navbar-nav topbar-nav ms-md-auto align-items-center">
            <li className="nav-item topbar-user dropdown hidden-caret">
              { authState?.isAuthenticated && (
              <>
              <span className="profile-username m-2">
                <span className="op-7">Hi, </span>
                <span className="fw-bold">{given_name}</span>
              </span>
              |
              <button type="button" className="btn btn-link btn-black btn-sm m-2" onClick={logout.bind(this)}> Logout </button>
              </>
            )}
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}