import React from "react"
import LeftMenu from '../components/LeftMenu';
import UserContent from "./UserContent"
import MainHeader from "../components/MainHeader"

export default function UserManagement() {

    return (
        <div className="wrapper sidebar_minimize">
            <LeftMenu />

            <div className="main-panel">
                <MainHeader pageTitle="Auto Renew | User Management" />

                <div className="container">
                    <div className="page-inner">
                        <UserContent />
                    </div>
                </div>
            </div>
        </div>
    )
}