import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from 'bootstrap';
import { useHistory } from "react-router-dom";
import clientHttp from '../../components/ClientHttp';

import BankFileStatusList from './BankFileStatusList';
import Pagination from '../../components/Pagination';
import { getBankList,getBankFileStatusList } from '../../components/DataList';

export default function BankFileStatusContent() {
    // auto load bank summary when first come
    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    const [toDate, setToDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
    const [bank, setBank] = useState('')
    const [bankFileStatus, setBankFileStatus] = useState('')
    const [data, setData] = useState(null);
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    // change page
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(50);
    const onPageChange = (page) => {
        setCurrentPage(page);
        console.log('select page: ' + page)
        searchSubmit(page)
    }
    let history = useHistory()

    async function searchSubmit(page) {
        setCurrentPage(page);
        setIsLoading(true)
        let isSuccess = false
        // clear data
        setData(null)

        console.log('call search api with parameters:' + currentPage, pageSize)
        // set token to header
        const startDate = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate()
        const endDate = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate()
        const formData = {
            startDate: startDate,
            endDate: endDate,
            bank: bank,
            bankFileStatus: bankFileStatus,
            page: page,
            pageSize: pageSize
        }
        let token = localStorage.getItem('token')

        await clientHttp.post('autorenew/v1/bankfile_status', formData, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then(response => {
                console.log('response status: ' + response.status)
                if (response.data.status === 'success') {
                    console.log('display bank file status')
                    setTotal(response.data.total)
                    setData(response.data.data)
                    isSuccess = true
                } else {
                    console.log('get bankfile_status failed: ' + response.data.message)
                    setError(response.data.message)
                }

            }).catch(err => {
                if (err.response.status === 401 || err.response.status === 403) {
                    console.log('no authorize, redirect to login page')
                    history.push(process.env.REACT_APP_LOGIN_URI)
                } else {
                    console.log('get bankfile_status failed: ' + err.message)
                    setError(err.message)
                }
            })

        if (!isSuccess) {
            let errorMessageModal = new Modal('#errorMessageModal')
            errorMessageModal.show()
        }
        //await new Promise( res => setTimeout(res, 1000) );
        setIsLoading(false)
    }

    const selectBank = (e) => { setBank(e.target.value) }
    const selectBankFileStatus = (e) => { setBankFileStatus(e.target.value) }
    const selectPageSize = (e) => { setPageSize(e.target.value) }

    // load data list for dropdown
    const [bankNameList, setBankNameList] = useState([])
    const [bankFileStatusList, setBankFileStatusList] = useState([])
    useEffect(() => {
        // get bank list
        (async () => {
            console.log('load bank list')
            setBankNameList(await getBankList())

            console.log('load bank file status list')
            setBankFileStatusList(await getBankFileStatusList())
        })();

    }, [])

    return (
        <>
            <div className="page-header">
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">From Date:</span>
                                            <DatePicker className="form-control" selected={fromDate} onChange={(date) => setFromDate(date)} dateFormat="dd/MM/yyyy" />
                                            <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">To Date:</span>
                                            <DatePicker className="form-control" selected={toDate} onChange={(date) => setToDate(date)} dateFormat="dd/MM/yyyy" />
                                            <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <select className="form-select" defaultValue={pageSize} onChange={selectPageSize}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="10">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                    {isLoading ?
                                            <button className="btn btn-black btn-border" style={{ "width": "60%" }} type="button">
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span role="status" className="ms-3">Loading...</span>
                                            </button>
                                            :
                                            <button type="button" className="btn btn-black btn-border" style={{ "width": "60%" }} onClick={searchSubmit.bind(this, 1)}> Search </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">Bank:</span>
                                            <select className="form-select" defaultValue={bank} onChange={selectBank}>
                                                <option value="">ALL</option>
                                                {
                                                    bankNameList?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.bankcode}>{item.bankname}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">Status:</span>
                                            <select className="form-select" defaultValue={bankFileStatus} onChange={selectBankFileStatus}>
                                                <option value="">ALL</option>
                                                {
                                                    bankFileStatusList?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.statuscode}>{item.statusname}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card card-round">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <BankFileStatusList data={data} searchSubmit={searchSubmit} />
                                </div>
                            </div>

                            <Pagination total={total} pageSize={pageSize} currentPage={currentPage} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className="modal fade" id="errorMessageModal" tabIndex="-1" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <p className="text-danger">{error}</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}