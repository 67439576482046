import React from 'react';
import LeftMenu from '../../components/LeftMenu';
import BankFileStatusContent from "./BankFileStatusContent"
import MainHeader from '../../components/MainHeader';

export default function BankFileStatusPage() {
    return (
        <div className="wrapper sidebar_minimize">
            <LeftMenu />

            <div className="main-panel">
                <MainHeader pageTitle="Auto Renew | Bank File Status" />

                <div className="container">
                    <div className="page-inner">
                        <BankFileStatusContent />
                    </div>
                </div>
            </div>
        </div>
    )
}