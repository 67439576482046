import React from 'react';
import ReactDOM from 'react-dom/client';

import "bootstrap/dist/js/bootstrap.bundle.js"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css"

import App from './App'

/*
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import LoginForm from './auth/LoginForm';
import AuthOkta from './auth/AuthOkta';
import DashboardPage from './autorenew/dashboard/DashboardPage';
import OrderStatusPage from './autorenew/orderstatus/OrderStatusPage';
import BankFileStatusPage from './autorenew/bankfilestatus/BankFileStatusPage';
import BankFileApprovalPage from './autorenew/bankfileapproval/BankFileApprovalPage';
import UserManagement from './user/UserManagement';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate replace to="/auth/login" />,
  },
  {
    path: "/auth/okta",
    element: <AuthOkta />,
  },
  {
    path: "/auth/login",
    element: <LoginForm />,
  },
  {
    path: "/autorenew/dashboard",
    element: <DashboardPage />,
  },
  {
    path: "/autorenew/orderstatus",
    element: <OrderStatusPage />,
  },
  {
    path: "/autorenew/bankfilestatus",
    element: <BankFileStatusPage />,
  },
  {
    path: "/autorenew/bankfileapproval",
    element: <BankFileApprovalPage />,
  },
  {
    path: "/user/usermanagement",
    element: <UserManagement />,
  }
]);
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
