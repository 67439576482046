import { useHistory } from 'react-router-dom';

export default function LogoutPage() {
    const history = useHistory();

    function enterWeb() {
        console.log(process.env.REACT_APP_FIRST_PAGE)
        history.push(process.env.REACT_APP_FIRST_PAGE)
    }

    return (
        <div className="container text-center">
            <div className="row justify-content-md-center m-5">
                <img src="/assets/img/amway-logo.jpg" style={{ width: 300 }} alt="Logo" />
            </div>
            <div className="row justify-content-md-center m-5">
                <h5>You're logout from AutoRenew</h5>
                {/* <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="ms-2" role="status">Login ... {</span> */}

                <div className="mt-3"><button type="button" className="btn btn-primary btn-sm" onClick={enterWeb.bind(this)}> Enter AutoRenew </button></div>
            </div>
        </div>
    )
}