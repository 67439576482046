import React from "react"

export default function UserList({ data, editUser, deleteUser }) {
    return (
        <>
            <div className="table-responsive table-hover table-sales">
                <table className="table table-striped table-hover align-items-center mb-0">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Usrename</th>
                            <th scope="col">Fullname</th>
                            <th scope="col">Role</th>
                            <th scope="col">Additional Role</th>
                            <th scope="col">Status</th>
                            <th scope="col">Edit</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((item) => {
                                let statusClass = ''
                                if (item.status === 'A') statusClass = 'text-center text-success'
                                else if (item.status === 'I') statusClass = 'text-center text-secondary'

                                return (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.username}</td>
                                        <td>{item.firstname} {item.lastname}</td>
                                        <td>{item.role}</td>
                                        <td>{item.additional_role}</td>
                                        <td><span className={statusClass}>{item.status}</span></td>
                                        <td><button type="button" className="btn btn-primary btn-sm" style={{ width: '80px' }} onClick={editUser.bind(this, item.id)}><i className="bi bi-pencil me-2"></i> Edit</button></td>
                                        <td><button type="button" className="btn btn-link text-danger" onClick={deleteUser.bind(this, item.id, item.username)}><i className="bi bi-trash" title="Delete"></i></button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}