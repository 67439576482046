import axios from "axios";

const clientHttp = axios.create({
// Amway API Gateway QA env
//    baseURL: 'https://t7izyqk89c.execute-api.ap-southeast-1.amazonaws.com/dev/api/',
// Joe AWS API Gateway Dev env
//    baseURL: 'https://nbnjt26vcd.execute-api.us-east-1.amazonaws.com/dev/api/',
// Local test
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: false,
    headers: {
      "Content-Type": "application/json"
    }
  });

  export default clientHttp;