// function for get data list such as Bank list, Status list
import clientHttp from './ClientHttp';

export async function getBankList() {
    console.log('call getBankList')
    let token = localStorage.getItem('token')

    let list = []
    await clientHttp.get('list/v1/bankname', {
        headers: { 'Authorization': 'Bearer ' + token }
    }).then(response => {
        console.log('response status: ' + response.status)
        if (response.data.status === 'success') {
            console.log('[getBankList] get bank list success')
            list = response.data.data
            return list
        } else {
            console.log('[getBankList] get bank list failed: ' + response.data.message)
        }

    }).catch(err => {
        console.log('[getBankList] error: ', err.message)
        if (err.response.status === 401 || err.response.status === 403) {
            console.log('[getBankList] no authorize')
        } else {
            console.log('[getBankList] get bank list failed: ' + err.message)
        }
    })

    return list
}

export async function getBankFileStatusList() {
    console.log('call getBankFileStatusList')
    let token = localStorage.getItem('token')

    let list = []
    await clientHttp.get('list/v1/bankfilestatus', {
        headers: { 'Authorization': 'Bearer ' + token }
    }).then(response => {
        console.log('response status: ' + response.status)
        if (response.data.status === 'success') {
            console.log('[getBankFileStatusList] get bank file status list success')
            list = response.data.data
            return list
        } else {
            console.log('[getBankFileStatusList] get bank file status list failed: ' + response.data.message)
        }

    }).catch(err => {
        console.log('[getBankFileStatusList] error: ', err.message)
        if (err.response.status === 401 || err.response.status === 403) {
            console.log('[getBankFileStatusList] no authorize')
        } else {
            console.log('[getBankFileStatusList] get bank list failed: ' + err.message)
        }
    })

    return list
}

export async function getOrderStatusList() {
    console.log('call getOrderStatusList')
    let token = localStorage.getItem('token')

    let list = []
    await clientHttp.get('list/v1/orderstatus', {
        headers: { 'Authorization': 'Bearer ' + token }
    }).then(response => {
        console.log('response status: ' + response.status)
        if (response.data.status === 'success') {
            console.log('[getOrderStatusList] get order status list success')
            list = response.data.data
            return list
        } else {
            console.log('[getOrderStatusList] get order status list failed: ' + response.data.message)
        }

    }).catch(err => {
        console.log('[getOrderStatusList] error: ', err.message)
        if (err.response.status === 401 || err.response.status === 403) {
            console.log('[getOrderStatusList] no authorize')
        } else {
            console.log('[getOrderStatusList] get order failed: ' + err.message)
        }
    })

    return list
}

export async function getPaymentStatusList() {
    console.log('call getPaymentStatusList')
    let token = localStorage.getItem('token')

    let list = []
    await clientHttp.get('list/v1/paymentstatus', {
        headers: { 'Authorization': 'Bearer ' + token }
    }).then(response => {
        console.log('response status: ' + response.status)
        if (response.data.status === 'success') {
            console.log('[getPaymentStatusList] get order status list success')
            list = response.data.data
            return list
        } else {
            console.log('[getPaymentStatusList] get order status list failed: ' + response.data.message)
        }

    }).catch(err => {
        console.log('[getPaymentStatusList] error: ', err.message)
        if (err.response.status === 401 || err.response.status === 403) {
            console.log('[getPaymentStatusList] no authorize')
        } else {
            console.log('[getPaymentStatusList] get order failed: ' + err.message)
        }
    })

    return list
}
