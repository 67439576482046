export function formatDate(inputDate) {
    if (inputDate instanceof Date && !isNaN(inputDate)) {
        const yyyy = inputDate.getFullYear();
        let mm = inputDate.getMonth() + 1; // Months start at 0!
        let dd = inputDate.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;
    } else {
        return ''
    }
}

export function formatDateTime(inputDate) {
    if (inputDate instanceof Date && !isNaN(inputDate)) {
        const yyyy = inputDate.getFullYear();
        let mm = inputDate.getMonth() + 1; // Months start at 0!
        let dd = inputDate.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        let hour = inputDate.getHours()
        let min = inputDate.getMinutes()
        let sec = inputDate.getSeconds()
        if (hour < 10) hour = '0' + hour;
        if (min < 10) min = '0' + min;
        if (sec < 10) sec = '0' + sec;

        return dd + '/' + mm + '/' + yyyy + ' ' + hour + ':' + min + ':' + sec;
    } else {
        return ''
    }
}
