import React from "react";
import LeftMenu from '../../components/LeftMenu';
import MainHeader from '../../components/MainHeader';
import DashboardContent from './DashboardContent';

export default function DashboardPage() {

    return (
        <div className="wrapper sidebar_minimize">
            <LeftMenu />

            <div className="main-panel">
                <MainHeader pageTitle="Auto Renew Direct Debit" />

                <div className="container">
                    <div className="page-inner">
                        <DashboardContent />
                    </div>
                </div>

            </div>
        </div>
    )
}