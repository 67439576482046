import React, { useState } from "react";
import { Modal } from 'bootstrap';
import { formatDate, formatDateTime } from "../../components/DateUtil";
import clientHttp from '../../components/ClientHttp';
import { useHistory } from "react-router-dom";

export default function OrderStatusList({ data, setSelectedOrderList }) {
    let history = useHistory()
    const [orderStateList, setOrderStateList] = useState(null)
    const [orderNoState, setOrderNoState] = useState('')
    const [aboNoState, setAboNoState] = useState('')
    const [amountState, setAmountState] = useState(0)

    async function handleOrderState(order) {
        setOrderStateList(null)
        setOrderNoState(order.orderno)
        setAboNoState(order.abono)
        setAmountState(order.amount)

        console.log('find order state of order no: ' + order.orderno)
        let token = localStorage.getItem('token')
        await clientHttp.post('autorenew/v1/order_state_all', { orderId: order.id }, {
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => {
            console.log('response status: ' + response.status)
            if (response.data.status === 'success') {
                console.log('display order state')
                setOrderStateList(response.data.data)
            } else {
                console.log('get order_state_all failed: ' + response.data.message)
            }

        }).catch(err => {
            console.log('error: ', err.message)
            if (err.response.status === 401 || err.response.status === 403) {
                console.log('no authorize, redirect to login page')
                history.push(process.env.REACT_APP_LOGIN_URI)
            } else {
                console.log('get order_state_all failed: ' + err.message)
            }
        })

        let orderStateHistoryModal = new Modal('#orderStateHistoryModal')
        orderStateHistoryModal.show()
    }

    function handleCaptureOrder(event) {
        const elements = document.getElementById("orderStatusList").getElementsByClassName("captureOrderId");
        console.log('find elements: ' + elements.length)

        let selectedId = []
        for (let i = 0; i < elements.length; i++) {
            elements[i].checked = event.target.checked
            if (event.target.checked) selectedId.push(elements[i].value)
        }

        // send list to parent
        setSelectedOrderList(selectedId)
    }

    function handleCaptureOrderByElement(event) {
        const elements = document.getElementById("orderStatusList").getElementsByClassName("captureOrderId");
        console.log('find elements: ' + elements.length)

        let selectedId = []
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].checked) selectedId.push(elements[i].value)
        }

        // send list to parent
        setSelectedOrderList(selectedId)
    }

    function ShowIcon({index, orderstatus, paymentstatus, validatestatus}) {
        //console.log('show icon: ', index, orderstatus, paymentstatus, validatestatus)
        if (index === 0) {
            return (
                <div className="timeline-badge">
                    <i className="icon-tag"></i>
                </div>
            )
        } else if (orderstatus === 'COMPLETED' || paymentstatus === 'PAID' || validatestatus === 'SUCCESS') {
            return (
                <div className="timeline-badge info">
                    <i className="icon-tag"></i>
                </div>
            )
        } else if (orderstatus === 'CANCELLED') {
            return (
                <div className="timeline-badge danger">
                    <i className="icon-close"></i>
                </div>
            )
        } else {
            return (
                <div className="timeline-badge info">
                    <i className="far fa-paper-plane"></i>
                </div>
            )
        }
    }

    function showOrderStatus(order) {
        // if (order.orderstatus === 'CANCELLED') return order.orderstatus
        // else if (order.validatestatus !== 'NONE') return order.validatestatus
        // else return order.orderstatus
        if (order.orderstatus === 'CANCELLED') return order.orderstatus
        else if (order.validatestatus === 'NONE' && order.paymentstatus === 'PAID') return order.paymentstatus
        else return order.validatestatus
    }

    return (
        <>
            <div id="orderStatusList" className="table-responsive table-hover table-sales">
                <table id="basic-datatables" className="table table-striped table-hover align-items-center mb-0">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col"><input type="checkbox" title="All or None" onChange={e => { handleCaptureOrder(e) }} /></th>
                            <th scope="col">No.</th>
                            <th scope="col">Order No.</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">ABO No.</th>
                            <th scope="col" className="text-end">Amount</th>
                            <th scope="col">Bank</th>
                            <th scope="col">Bank Status</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Remarks</th>
                            <th scope="col">File Id</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((order) => {
                                let orderStatus = showOrderStatus(order)
                                let classBank = order.bankstatus === 'SUCCESS' ? 'text-success' : order.bankstatus === 'ERROR' ? 'text-danger' : 'text-black'
                                let classOrder = orderStatus === 'SUCCESS' || orderStatus === 'PAID' ? 'text-primary' : orderStatus === 'CANCELLED' ? 'text-danger' : 'text-black'
                                //let classValidate = order.validatestatus === 'SUCCESS' ? 'text-success' : order.validatestatus === 'ERROR' ? 'text-danger' : 'text-black'
                                return (
                                    <tr key={order.id}>
                                        <th><input type="checkbox" value={order.id} className="captureOrderId" onChange={e => { handleCaptureOrderByElement(e) }} /></th>
                                        <td>{order.id}</td>
                                        <td>{order.orderno}
                                            <button type="button" title="Order State" className="btn btn-link link-secondary link-offset-2 link-underline link-underline-opacity-0" onClick={handleOrderState.bind(this, order)}>
                                                <i className="bi bi-stack"></i>
                                            </button>
                                        </td>
                                        <td>{formatDate(new Date(order.orderdate))}</td>
                                        <td>{order.abono}</td>
                                        <td className="text-end">{
                                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(order.amount).replace("USD", "").trim()}</td>
                                        <td>{order.bank}</td>
                                        <td><span className={classBank}>{order.bankstatus}</span></td>
                                        <td><span className={classOrder}>{orderStatus}</span></td>
                                        <td><span>{order.remarks}</span></td>
                                        <td>{order.fileid}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            {/* order state history */}
            <div className='row'>
                <div className='col-md-12'>
                    <div className="modal fade" id="orderStateHistoryModal" tabIndex="-1" >
                        <div className="modal-dialog modal-lg ">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="fw-bold mb-3">Order: {orderNoState} <i className="bi bi-record2 text-secondary"></i> 
                                    ABO: {aboNoState} <i className="bi bi-record2 text-secondary"></i> 
                                    Amount: {
                                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(amountState).replace("USD", "").trim()}</h4>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>

                                <div className="modal-body">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="timeline">
                                                {orderStateList?.map((order, index) => {
                                                    return (
                                                        <li key={index} className={(index % 2) === 0 ? '' : 'timeline-inverted'}>
                                                            <ShowIcon index={index} orderstatus={order.orderstatus} paymentstatus={order.paymentstatus} validatestatus={order.validatestatus} />
                                                            <div className="timeline-panel">
                                                                <div className="timeline-heading">
                                                                    <h4 className="timeline-title">{formatDateTime(new Date(order.statuschangedt))}</h4>
                                                                </div>
                                                                <div className="timeline-body">
                                                                    <p><i className="icon-layers fa-lg"></i> Order: {order.orderstatus}</p>
                                                                    <p><i className="fab fa-bitcoin fa-lg"></i> Payment: {order.paymentstatus}</p>
                                                                    <p><i className="bi bi-check2-circle fa-lg"></i> Validate: {order.validatestatus}</p>
                                                                    <p><small className="text-mute">{order.remarks}</small></p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}