import React from 'react';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { BrowserRouter as Router, Route, useHistory, Redirect } from 'react-router-dom';
import { oktaAuthConfig } from "./okta_config";

import LoginForm from './auth/LoginForm';
import AuthOkta from './auth/AuthOkta';
import DashboardPage from './autorenew/dashboard/DashboardPage';
import OrderStatusPage from './autorenew/orderstatus/OrderStatusPage';
import BankFileStatusPage from './autorenew/bankfilestatus/BankFileStatusPage';
import BankFileApprovalPage from './autorenew/bankfileapproval/BankFileApprovalPage';
import UserManagement from './user/UserManagement';
import LogoutPage from './auth/LogoutPage';
import UnauthorizePage from './auth/UnauthorizePage'

const oktaAuth = new OktaAuth(oktaAuthConfig);
// const oktaAuth = new OktaAuth({
//         issuer: `${process.env.REACT_APP_OKTA_BASE_URL}/oauth2/default`,
//         clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
//         redirectUri: window.location.origin + '/login/callback',
//         scopes: ["openid", "profile", "email"],
//         pkce: true,
//       });

const App = () => {
        const history = useHistory();
        const restoreOriginalUri = async (_oktaAuth, originalUri) => {
                history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
        };

        return (
                <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                        <Route path="/" exact={true}>
                                <Redirect to="/auth/okta" />
                        </Route>
                        <Route path="/login/callback" component={LoginCallback} />
                        <Route path="/QA/login/callback" component={LoginCallback} />
                        <Route path="/auth/logout" component={LogoutPage} />
                        <Route path="/auth/unauthorize" component={UnauthorizePage} />

                        <Route path="/auth/login" component={LoginForm} />
                        <SecureRoute path="/auth/okta" component={AuthOkta} />
                        <SecureRoute path="/autorenew/dashboard" component={DashboardPage} />
                        <SecureRoute path="/autorenew/orderstatus" component={OrderStatusPage} />
                        <SecureRoute path="/autorenew/bankfilestatus" component={BankFileStatusPage} />
                        <SecureRoute path="/autorenew/bankfileapproval" component={BankFileApprovalPage} />
                        <SecureRoute path="/user/usermanagement" component={UserManagement} />
                </Security>
        );
};

const AppWithRouterAccess = () => (
        <Router>
                <App />
        </Router>
);

export default AppWithRouterAccess;