import React from "react";

export default function UnauthorizePage() {

    return (
        <div className="container text-center">
            <div className="row justify-content-md-center m-5">
                <img src="/assets/img/amway-logo.jpg" style={{ width: 300 }} alt="Logo" />
            </div>
            <div className="row justify-content-md-center m-5">
                <i className="bi bi-x-circle-fill text-danger h1"></i>
                <span className="text-danger fs-3">You have not authority to access this system</span>
            </div>
        </div>
    )
}