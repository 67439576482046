import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import clientHttp from '../../components/ClientHttp';
import { formatDateTime } from "../../components/DateUtil";

export default function GenerateBankFile({ data, cancelGenerateBankFile }) {
    const [generateBankFileResult, setGenerateBankFileResult] = useState(data)
    const [isLoading, setIsLoading] = useState(false)
    const [finishGenerate, setFinishGenerate] = useState(false)
    let history = useHistory()

    useEffect(() => {
        // for refresh list of order, do not remove
    }, [generateBankFileResult])

    async function confirmGenerateBankFile() {
        setIsLoading(true);

        let isSuccess = false
        let token = localStorage.getItem('token')
        const formData = JSON.stringify(generateBankFileResult)
        console.log(formData)

        let resultData = null
        await clientHttp.post('autorenew/v1/generate_bankfile', formData, {
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => {
            console.log('response status: ' + response.status)
            if (response.data.status === 'success') {
                console.log('display order status')
                isSuccess = true
                resultData = response.data.data
            } else {
                console.log('get order_status failed: ' + response.data.message)
            }

        }).catch(err => {
            console.log('error: ', err.message)
            if (err.response.status === 401 || err.response.status === 403) {
                console.log('no authorize, redirect to login page')
                history.push(process.env.REACT_APP_LOGIN_URI)
            } else {
                console.log('get order_status failed: ' + err.message)
            }
        })

        // check status of process
        console.log(resultData)
        for (let i = 0; i < generateBankFileResult.length; i++) {
            let order = generateBankFileResult[i]
            const ele = resultData.find((item) => item.orderId === order.id);
            if (ele) {
                order.result = ele.result
            }
        }

        // temp delay
        //await new Promise( res => setTimeout(res, 1000) );
        setIsLoading(false);
        setFinishGenerate(true);
    }

    async function deleteSelectedOrder(orderId) {
        const updateList = generateBankFileResult.filter((item) => item.id !== orderId);
        setGenerateBankFileResult(updateList)
    }

    return (
        <>
            <div className="row m-3">
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <h5>List of orders to generate bank file</h5>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    {isLoading ?
                        <button type="button" className="btn btn-secondary ms-3" style={{ "width": "40%" }}>
                            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
                            Generating ...
                        </button>
                        :
                        (!finishGenerate && generateBankFileResult.length > 0) && 
                        <>
                            <button type="button" className="btn btn-success ms-3" style={{ "width": "40%" }} onClick={confirmGenerateBankFile.bind(this)}>Confirm to generate</button>
                            <button type="button" className="btn btn-dark ms-3" onClick={cancelGenerateBankFile.bind(this)}>Cancel</button>
                        </>
                    }
                    {(data.length === 0 || finishGenerate) &&
                        <button type="button" className="btn btn-secondary ms-3" style={{ "width": "40%" }} onClick={cancelGenerateBankFile.bind(this)}>Close</button>
                    }
                </div>
            </div>
            <div className="table-responsive table-hover table-sales">
                <table id="basic-datatables" className="table table-striped table-hover align-items-center mb-0">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Order No.</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">ABO No.</th>
                            <th scope="col" className="text-end">Amount</th>
                            <th scope="col">Result</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            generateBankFileResult?.map((order) => {
                                return (
                                    <tr key={order.id}>
                                        <td>{order.id}</td>
                                        <td>{order.orderno}</td>
                                        <td>{formatDateTime( new Date(order.orderdate)) }</td>
                                        <td>{order.abono}</td>
                                        <td className="text-end">{
                                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(order.amount).replace("USD", "").trim()}</td>
                                        <td>{order.result}</td>
                                        <td><button type="button" className="btn btn-link text-danger" onClick={deleteSelectedOrder.bind(this, order.id)}><i className="bi bi-trash" title="Delete"></i></button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}