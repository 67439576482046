import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from 'bootstrap';
import { useHistory } from "react-router-dom";
import clientHttp from '../../components/ClientHttp';

import BankFileApprovalList from './BankFileApprovalList';
import Pagination from '../../components/Pagination';
import { getBankList,getBankFileStatusList } from '../../components/DataList';

export default function BankFileApprovalContent() {
    // auto load bank summary when first come
    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    const [toDate, setToDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
    const [bank, setBank] = useState('')
    const [approveStatus, setApproveStatus] = useState('')
    const [data, setData] = useState(null);
    const [error, setError] = useState(null)
    const [errorMessageClass, setErrorMessageClass] = useState('')
    const [isApproveBankFile, setIsApproveBankFile] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // change page
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(50);
    const onPageChange = (page) => {
        setCurrentPage(page);
        console.log('select page: ' + page)
        searchSubmit(page)
    }
    let history = useHistory()

    async function searchSubmit(page) {
        setCurrentPage(page);
        setErrorMessageClass('text-danger')
        setIsApproveBankFile(false)
        setIsLoading(true)
        let isSuccess = false
        // clear data
        setData(null)

        console.log('call search api with parameters:' + currentPage, pageSize)
        // set token to header
        const startDate = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate()
        const endDate = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate()
        const formData = {
            startDate: startDate, 
            endDate: endDate, 
            bank: bank,
            approveStatus: approveStatus,
            page: page, 
            pageSize: pageSize
        }
        let token = localStorage.getItem('token')

        await clientHttp.post('autorenew/v1/bankfile_approval', formData, {
            headers: {'Authorization': 'Bearer ' + token}
        })
        .then(response => {
            console.log('response status: ' + response.status)
            if (response.data.status === 'success') {
                console.log('display bank file approval')
                setTotal(response.data.total)
                setData(response.data.data)
                isSuccess = true
            } else {
                console.log('get bankfile_approval failed: ' + response.data.message)
                setError(response.data.message)
            }
            
        }).catch(err => {
            console.log('error: ', err.message)
            if (err.response.status === 401 || err.response.status === 403) {
                console.log('no authorize, redirect to login page')
                history.push(process.env.REACT_APP_LOGIN_URI)
            } else {
                console.log('get bankfile_approval failed: ' + err.message)
                setError(err.message)
            }
        })

        if (!isSuccess) {
            let errorMessageModal = new Modal('#errorMessageModal')
            errorMessageModal.show()
        }
        //await new Promise( res => setTimeout(res, 1000) );
        setIsLoading(false)
    }

    const selectBank = (e) => { setBank(e.target.value)}
    const selectApproveStatus = (e) => { setApproveStatus(e.target.value)}
    const selectPageSize = (e) => { setPageSize(e.target.value)}

    // Handle to approve and reject file
    const [fileId, setFileId] = useState('')
    const [fileStatus, setFileStatus] = useState('')
    const [approveBankFileModal, setApproveBankFileModal] = useState(null)
    const approveFileHandle = (fileId, status) => {
        console.log('[approveFileHandle] fileId: ' + fileId + ', ' + status)
        setFileId(fileId)
        setFileStatus(status)

        if (status === 'APPROVED') {
            let approveBankFileModal = new Modal('#approveBankFileModal')
            approveBankFileModal.show()
            setApproveBankFileModal(approveBankFileModal)
        } else if (status === 'REJECTED') {
            let rejectBankFileModal = new Modal('#rejectBankFileModal')
            rejectBankFileModal.show()
            setApproveBankFileModal(rejectBankFileModal)
        }
    }
    async function confirmApproveFileHandle()  {
        setErrorMessageClass('text-danger')
        setIsApproveBankFile(true)

        console.log('confirm approve bank file: ' + fileId + ', ' + fileStatus)
        let isSuccess = false

        // load user
        let token = localStorage.getItem('token')
        const formData = {
            fileId: fileId,
            status: fileStatus,
            user: localStorage.getItem('name')
        }
        console.log('form data')
        console.log(formData)
        
        await clientHttp.post('autorenew/v1/update_bankfilestatus', formData, {
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => {
            const data = response.data
            console.log('response status: ' + data.status + ', row: ' + data.message)
            setError(data.message)
            if (data.status === 'success') {
                setErrorMessageClass('text-success')
                isSuccess = true
            }
        }).catch(err => {
            console.log('error: ', err.message)
            if (err.response.status === 401 || err.response.status === 403) {
                console.log('no authorize, redirect to login page')
                history.push(process.env.REACT_APP_LOGIN_URI)
            } else {
                console.log('save user failed: ' + err.message)
                setError(err.message)
            }
        })

        // close previous modal
        approveBankFileModal.hide()
        // show result
        let errorMessageModal = new Modal('#errorMessageModal')
        errorMessageModal.show()
    }

    // load data list for dropdown
    const [bankNameList, setBankNameList] = useState([])
    const [bankFileStatusList, setBankFileStatusList] = useState([])
    useEffect(() => {
        // get bank list
        (async () => {
            console.log('load bank list')
            setBankNameList(await getBankList())

            console.log('load bank file status list')
            setBankFileStatusList(await getBankFileStatusList())

        })();

    }, [])
    
    return (
        <>
            <div className="page-header">
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">From Date:</span>
                                            <DatePicker className="form-control" selected={fromDate} onChange={(date) => setFromDate(date)} dateFormat="dd/MM/yyyy" />
                                            <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">To Date:</span>
                                            <DatePicker className="form-control" selected={toDate} onChange={(date) => setToDate(date)} dateFormat="dd/MM/yyyy" />
                                            <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <select className="form-select" defaultValue={pageSize} onChange={selectPageSize}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="10">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                    {isLoading ?
                                            <button className="btn btn-black btn-border" style={{ "width": "60%" }} type="button">
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span role="status" className="ms-3">Loading...</span>
                                            </button>
                                            :
                                            <button type="button" className="btn btn-black btn-border" style={{ "width": "60%" }} onClick={searchSubmit.bind(this, 1)}> Search </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">Bank:</span>
                                            <select className="form-select" defaultValue={bank} onChange={selectBank}>
                                                <option value="">ALL</option>
                                                {
                                                    bankNameList?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.bankcode}>{item.bankname}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">Status:</span>
                                            <select className="form-select" defaultValue={approveStatus} onChange={selectApproveStatus}>
                                                <option value="">ALL</option>
                                                <option value="Waiting">Waiting</option>
                                                <option value="Approved">Approved</option>
                                                <option value="Rejected">Rejected</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card card-round">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <BankFileApprovalList data={data} approveFileHandle={approveFileHandle} />
                                </div>
                            </div>
                            
                            <Pagination total={total} pageSize={pageSize} currentPage={currentPage} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className="modal fade" id="errorMessageModal" tabIndex="-1" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <p className={errorMessageClass}>{error}</p>
                                </div>
                                <div className="modal-footer">
                                    {isApproveBankFile ?
                                    <button type="button" className="btn btn-dark" onClick={searchSubmit.bind(this, 1)} data-bs-dismiss="modal">Close</button>
                                    :
                                    <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className="modal fade" id="approveBankFileModal" tabIndex="-1" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Approve</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p className="text-dark">Confirm to approve file: {fileId}</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-success" onClick={confirmApproveFileHandle.bind(this)}>Confirm to approve</button>
                                    <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="modal fade" id="rejectBankFileModal" tabIndex="-1" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Reject</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p className="text-danger">Confirm to reject file: {fileId}</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" onClick={confirmApproveFileHandle.bind(this)}>Confirm to reject</button>
                                    <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}