import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import clientHttp from '../../components/ClientHttp';
import { formatDateTime } from "../../components/DateUtil";

export default function CaptureOrderList({ data, cancelCaptureOrder }) {
    const [captureResult, setCaptureResult] = useState(data)
    const [isLoading, setIsLoading] = useState(false)
    const [finishCapture, setFinishCapture] = useState(false)
    let history = useHistory()

    useEffect(() => {
        // for refresh list of order, do not remove
    }, [captureResult])

    async function confirmCaptureOrder() {
        setIsLoading(true);

        console.log('send order to capture payment')
        for (let i = 0; i < captureResult.length; i++) {
            let order = captureResult[i]
            console.log(`order no: ${order.orderno}, abo: ${order.abono}, amount: ${order.amount}`)

            let isSuccess = false
            // call api
            let formData = {
                orderType: 'ATRN', // autorenew
                id: order.id,
                orderNo: order.orderno,
                aboNo: order.abono,
                amount: order.amount
            }
            let token = localStorage.getItem('token')
            console.log(formData)
            await clientHttp.post('autorenew/v1/capture_payment', formData, {
                headers: { 'Authorization': 'Bearer ' + token }
            }).then(response => {
                console.log('response status: ' + response.status)
                if (response.data.status === 'success') {
                    console.log('display order status')
                    isSuccess = true
                } else {
                    console.log('get order_status failed: ' + response.data.message)
                }

            }).catch(err => {
                console.log('error: ', err.message)
                if (err.response.status === 401 || err.response.status === 403) {
                    console.log('no authorize, redirect to login page')
                    history.push(process.env.REACT_APP_LOGIN_URI)
                } else {
                    console.log('get order_status failed: ' + err.message)
                }
            })

            // process if success
            if (isSuccess) {
                order.result = 'Success'
            } else {
                order.result = 'Error'
            }
        }

        // temp delay
        //await new Promise( res => setTimeout(res, 1000) );
        setIsLoading(false);
        setFinishCapture(true);
    }

    async function deleteSelectedOrder(orderId) {
        const updateList = captureResult.filter((item) => item.id !== orderId);
        setCaptureResult(updateList)
    }

    async function hybrisCaptureOrder() {
        setIsLoading(true);

        console.log('send order to Hybris capture payment')
        for (let i = 0; i < captureResult.length; i++) {
            let order = captureResult[i]
            console.log(`order no: ${order.orderno}, abo: ${order.abono}, amount: ${order.amount}`)

            let isSuccess = false
            // call api
            let formData = {
                orderId: order.id,
                orderNo: order.orderno,
            }
            let token = localStorage.getItem('token')
            console.log(formData)
            await clientHttp.post('hybris/v1/capture_payment', formData, {
                headers: { 'Authorization': 'Bearer ' + token }
            }).then(response => {
                console.log('response status: ' + response.status)
                if (response.data.status === 'success') {
                    console.log('display order status')
                    isSuccess = true
                } else {
                    console.log('get order_status failed: ' + response.data.message)
                }

            }).catch(err => {
                console.log('error: ', err.message)
                if (err.response.status === 401 || err.response.status === 403) {
                    console.log('no authorize, redirect to login page')
                    history.push(process.env.REACT_APP_LOGIN_URI)
                } else {
                    console.log('get order_status failed: ' + err.message)
                    console.log('capture message: ' + err.response.data.message)
                }
            })

            // process if success
            if (isSuccess) {
                order.result = 'Success'
            } else {
                order.result = 'Error'
            }
        }

        // temp delay
        //await new Promise( res => setTimeout(res, 1000) );
        setIsLoading(false);
        setFinishCapture(true);
    }
    return (
        <>
            <div className="row m-3">
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <h5>List of orders to capture</h5>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    {isLoading ?
                        <button type="button" className="btn btn-secondary ms-3" style={{ "width": "40%" }}>
                            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
                            Capturing ...
                        </button>
                        :
                        (!finishCapture && captureResult.length > 0) && 
                        <>
                            <button type="button" className="btn btn-success ms-3" style={{ "width": "40%" }} onClick={confirmCaptureOrder.bind(this)}>Confirm to capture</button>
                            <button type="button" className="btn btn-dark ms-3" onClick={cancelCaptureOrder.bind(this)}>Cancel</button>
                        </>
                    }
                    {(data.length === 0 || finishCapture) &&
                        <button type="button" className="btn btn-secondary ms-3" style={{ "width": "40%" }} onClick={cancelCaptureOrder.bind(this)}>Close</button>
                    }
                </div>
            </div>
            <div className="table-responsive table-hover table-sales">
                <table id="basic-datatables" className="table table-striped table-hover align-items-center mb-0">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Order No.</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">ABO No.</th>
                            <th scope="col" className="text-end">Amount</th>
                            <th scope="col">Result</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            captureResult?.map((order) => {
                                return (
                                    <tr key={order.id}>
                                        <td>{order.id}</td>
                                        <td>{order.orderno}</td>
                                        <td>{formatDateTime( new Date(order.orderdate)) }</td>
                                        <td>{order.abono}</td>
                                        <td className="text-end">{
                                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(order.amount).replace("USD", "").trim()}</td>
                                        <td>{order.result}</td>
                                        <td><button type="button" className="btn btn-link text-danger" onClick={deleteSelectedOrder.bind(this, order.id)}><i className="bi bi-trash" title="Delete"></i></button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}