import React from "react";
import { formatDate } from "../../components/DateUtil";
import { useHistory } from "react-router-dom";

export default function BankSummary({ fromDate, toDate, data }) {
    let history = useHistory()

    function handleOrderStatusList(type, order) {
        // month not plus 1 because have to create new Date() 
        const startDate = fromDate.getFullYear() + '-' + fromDate.getMonth() + '-' + fromDate.getDate()
        const endDate = toDate.getFullYear() + '-' + toDate.getMonth() + '-' + toDate.getDate()
        let params = 'startdate=' + startDate + '&enddate=' + endDate + '&bank=' + order.bank
        if (type === 'bank_failed') {
            params += '&bankstatus=ERROR'
        }
        if (type === 'order_failed') {
            params += '&validatestatus=ERROR'
        }

        history.push('/autorenew/orderstatus?' + params)
    }

    return (
        <div className="card card-round">
            <div className="card-header">
                <div className="card-head-row card-tools-still-right">
                    <h4 className="card-title">Statistic for {fromDate.toLocaleString('en-us', { month: 'short', year: 'numeric' })}</h4>
                    <div className="card-tools">
                    </div>
                </div>
                <p className="card-category">
                    Between {('0' + fromDate.getDate()).slice(-2) + '/' + ('0' + (fromDate.getMonth() + 1)).slice(-2) + '/' + fromDate.getFullYear() + ' - ' +
                        ('0' + toDate.getDate()).slice(-2) + '/' + ('0' + (toDate.getMonth() + 1)).slice(-2) + '/' + toDate.getFullYear()}
                </p>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive table-hover table-sales">
                            <table className="table table-striped table-hover align-items-center mb-0">
                                <thead className="thead-light">
                                    <tr>
                                        <th rowSpan={2} scope="col">Order date</th>
                                        <th rowSpan={2} scope="col">Bank</th>
                                        <th rowSpan={2} scope="col" className="text-end">Rec Num.</th>
                                        <th rowSpan={2} scope="col" className="text-end">Amount</th>
                                        <th colSpan={3} scope="col" className="text-center">Bank Status</th>
                                        <th colSpan={3} scope="col" className="text-center">Order Status</th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className="text-end">Success</th>
                                        <th scope="col" className="text-end">Error</th>
                                        <th scope="col" className="text-end">Others</th>
                                        <th scope="col" className="text-end">Success</th>
                                        <th scope="col" className="text-end">Error</th>
                                        <th scope="col" className="text-end">Others</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.map((order, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{formatDate(new Date(order.order_date))}</td>
                                                    <td>{order.bank}</td>
                                                    <td className="text-end">{new Intl.NumberFormat().format(order.rec_num)}
                                                        <button type="button" title="Order list" className="btn btn-link link-secondary link-offset-2 link-underline link-underline-opacity-0" onClick={handleOrderStatusList.bind(this, 'none', order)}>
                                                            <i className="bi bi-layers"></i>
                                                        </button>
                                                    </td>
                                                    <td className="text-end">{
                                                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                            .format(order.total_amount).replace("USD", "").trim()}</td>
                                                    <td className="text-end text-success">{new Intl.NumberFormat().format(order.bank_success)}</td>
                                                    <td className="text-end text-danger">{new Intl.NumberFormat().format(order.bank_failed)}
                                                    <button type="button" title="Bank error list" className="btn btn-link link-secondary link-offset-2 link-underline link-underline-opacity-0" onClick={handleOrderStatusList.bind(this, 'bank_failed', order)}>
                                                            <i className="bi bi-layers text-danger"></i>
                                                        </button>
                                                    </td>
                                                    <td className="text-end">{new Intl.NumberFormat().format(order.rec_num - order.bank_success - order.bank_failed)}</td>
                                                    <td className="text-end text-success">{new Intl.NumberFormat().format(order.order_success)}</td>
                                                    <td className="text-end text-danger">{new Intl.NumberFormat().format(order.order_failed)}
                                                    <button type="button" title="Order error list" className="btn btn-link link-secondary link-offset-2 link-underline link-underline-opacity-0" onClick={handleOrderStatusList.bind(this, 'order_failed', order)}>
                                                            <i className="bi bi-layers text-danger"></i>
                                                        </button>
                                                    </td>
                                                    <td className="text-end">{new Intl.NumberFormat().format(order.rec_num - order.order_success - order.order_failed)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}