import React from "react";
import { formatDateTime } from "../../components/DateUtil";


export default function BankFileApprovalList({ data, approveFileHandle }) {
    return (
        <div className="table-responsive table-hover table-sales">
            <table className="table table-striped table-hover align-items-center mb-0">
                <thead className="thead-light">
                    <tr>
                        <th scope="col">No.</th>
                        <th scope="col">File ID</th>
                        <th scope="col">Bank</th>
                        <th scope="col">Gen Date</th>
                        <th scope="col">Upload Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Approved By</th>
                        <th scope="col" className="text-end">Rec. Num</th>
                        <th scope="col" className="text-end">Amount</th>
                        <th scope="col">Approve</th>
                        <th scope="col">Reject</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.map((item) => {
                            let isWaiting = false;
                            if (item.status === 'PENDING_APPROVAL') {
                                isWaiting = true
                            }
                            let classBankStatus = item.status === 'APPROVED' ? 'text-primary' : item.status === 'REJECTED' ? 'text-danger' : 'text-black'

                            return (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.fileid}</td>
                                    <td>{item.bank}</td>
                                    <td>{formatDateTime(new Date(item.gendate))}</td>
                                    <td>{formatDateTime(new Date(item.lastimport))}</td>
                                    <td className={classBankStatus}>{item.status}</td>
                                    <td>{item.approvedby}<br/>{formatDateTime(new Date(item.approveddate))}</td>
                                    <td className="text-end">{item.recnum}</td>
                                    <td className="text-end">{
                                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                            .format(item.amount).replace("USD", "").trim()}</td>
                                    <td>
                                        {
                                            isWaiting ?
                                            <button type="button" className="btn btn-success btn-sm" onClick={approveFileHandle.bind(this, item.fileid, 'APPROVED')}><span className="btn-label">Approve</span></button>
                                            : ''
                                        }
                                    </td>
                                    <td>
                                        {
                                            isWaiting ?
                                            <button type="button" className="btn btn-danger btn-sm" onClick={approveFileHandle.bind(this, item.fileid, 'REJECTED')}><span className="btn-label">Reject</span></button>
                                            : ''
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>

    )
}