import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import clientHttp from '../components/ClientHttp'

export default function LoginForm() {
    const username = useRef()
    const password = useRef()
    let history = useHistory()
    const [error, setError] = useState(null)

    const signIn = async () => {
        const _user = username.current.value
        const _pass = password.current.value

        const formData = {
            username: _user,
            password: _pass
        }
        await clientHttp.post('auth/v1/login', formData
        )
            .then(response => {
                console.log('login status: ' + response.status)
                console.log(response)
                if (response.data.status === 'success') {
                    console.log('login success then redirect to dashboard')
                    localStorage.setItem('token', response.data.token)
                    history.push(process.env.REACT_APP_FIRST_PAGE)
                } else {
                    console.log('login failed: ' + response.data.message)
                    setError(response.data.message)
                }
            }).catch(err => {
                console.log('login failed')
                if (err.response.status === 401 || err.response.status === 403) {
                    setError(err.response.data.message)
                } else {
                    setError(err.message)
                }
            })
    }

    return (
        <div className="container text-center">
            <div className="row justify-content-md-center m-5">
                <img src="/assets/img/amway-logo.jpg" style={{ width: 300 }} alt="Logo" />
            </div>
            <div className="row justify-content-md-center m-5">
                <div className="card" style={{ width: '20rem' }}>
                    <div className="card-body">
                        <form className="text-start">
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Username</label>
                                <input ref={username} type="text" className="form-control" id="username" defaultValue="admin" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input ref={password} type="password" className="form-control" id="password" defaultValue="NextGen2024" />
                            </div>
                            <button type="button" className="btn btn-primary w-100" onClick={signIn}>Sign in</button>
                        </form>
                        {
                            error &&
                            <p className="text-danger mt-2 fs-6">{error}</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}